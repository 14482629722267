import React, { useContext } from 'react'
import { Router } from '@reach/router'

import InstantQuote3DPrintingView from '../../views/instant-quote/3d-printing'
import { UserContext } from '../../context/UserManager'

const InstantQuote = () => {
  const { user } = useContext(UserContext)

  return (
    <Router>
      <InstantQuote3DPrintingView
        path="/instant-quote/3d-printing/"
        anonymousUser={!user}
      />
    </Router>
  )
}

export default InstantQuote
